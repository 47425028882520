import React from "react";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Pagination from "../utilities/pagination";

const Posts = ({ posts, totalPages, currentPage, baseUri, type, category }) => {
  return (
    <>
      <section className=" bg-white">
        <div className="container sz">
          <div className="section-group">
            {posts.edges && posts.edges.length ? (
              posts.edges.map(({ node: post }, i) => (
                <div className="row row-media" key={i}>
                  <div className="col-lg-4 py-4">
                    {post.astroturfTv?.astroturfTvEmbed && (
                      <div className="ratio ratio-16x9">
                        <iframe
                          width="560"
                          height="315"
                          src={post.astroturfTv.astroturfTvEmbed}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}
                    {post.featuredImage && !post.astroturfTv?.astroturfTvEmbed && (
                      <Link
                        className="d-block product product-green"
                        to={post.uri}
                      >
                        <CmsImage
                          className="img-zoom img-fluid w-100 img-clipped mx-auto d-block "
                          image={post.featuredImage.node}
                        />
                      </Link>
                    )}
                  </div>
                  <div className="col-lg-8 py-4 ">
                    <p className="tk-rift text-uppercase text-secondary mb-0">
                      <small>{post.date} </small>
                      {post.tags.nodes &&
                        post.tags.nodes.map((cat, i) => (
                          <Link
                            to={cat.uri}
                            key={i}
                            className="text-decoration-none blog-tags"
                            activeClassName="active"
                          >
                            |<small className="px-1">{cat.name}</small>
                          </Link>
                        ))}
                    </p>

                    <Link className="text-decoration-none" to={post.uri}>
                      <h2 className="display-5 text-secondary text-uppercase tk-rift fw-bold">
                        {post.title}
                      </h2>
                    </Link>

                    <Link
                      className="moretag text-decoration-none text-uppercase fw-bold lead"
                      to={post.uri}
                    >
                      Read more
                      <svg
                        className="ms-3"
                        width="18"
                        height="9"
                        viewBox="0 0 18 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0782 4.07357C17.2781 4.26875 17.2859 4.58544 17.0955 4.78091L13.9934 7.96632C13.8031 8.1618 13.4867 8.16203 13.2868 7.96685C13.0869 7.77166 13.0791 7.45497 13.2695 7.2595L15.5394 4.92856L0.377547 4.92856L0.377256 3.92822L15.515 3.92822L13.131 1.60073C12.9311 1.40555 12.9234 1.08886 13.1137 0.893384C13.3041 0.697912 13.6205 0.697678 13.8204 0.892862L17.0782 4.07357Z"
                          fill="#00A950"
                        ></path>
                      </svg>
                      <br></br>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <>
                <h1>No Posts</h1>
                <p>
                  This {type ? type : "category"} has no posts... check back
                  soon!
                </p>
              </>
            )}
          </div>
        </div>
      </section>

      {totalPages && totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      )}
    </>
  );
};

export default Posts;
