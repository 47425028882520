import React from "react";
import Link from "../utilities/link";

const CategoryMenu = ({ categories, tax }) => {
  return (
    <section className="section cat-menu clipped-small-top bg-white py-5">
      <div className="container sz">
        <div className="media-nav">
          <ul className="nav">
            {categories &&
              categories.map((cat, key) => {
                if (!cat.node.parentId) {
                  return (
                    <li className="nav-item" key={key}>
                      {cat.node.slug !== "uncategorized" && (
                        <div>
                          <Link
                            className={`nav-link ${
                              cat.node.id === tax?.parentId ? "active" : ""
                            }`}
                            activeClassName="active"
                            to={`${cat.node.uri}`}
                          >
                            {cat.node.name}
                          </Link>
                        </div>
                      )}
                    </li>
                  );
                }
              })}
          </ul>
          <ul className="nav nav-sub">
            {categories &&
              categories.map((cat, key) => {
                if (
                  (cat.node.parentId && tax?.id === cat.node.parentId) ||
                  (cat.node.parentId &&
                    tax?.parentId &&
                    cat.node.parentId === tax?.parentId)
                ) {
                  return (
                    <li className="nav-item" key={key}>
                      {cat.node.slug !== "uncategorized" && (
                        <div>
                          <Link
                            className="nav-link"
                            activeClassName="active"
                            to={`${cat.node.uri}`}
                          >
                            {cat.node.name}
                          </Link>
                        </div>
                      )}
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CategoryMenu;
