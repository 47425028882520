import React from "react";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Pagination from "../utilities/pagination";

const VideoPosts = ({ posts, totalPages, currentPage, baseUri, type }) => {
  return (
    <>
      <section className=" bg-white">
        <div className="container sz">
          <div className="section-group">
            <div className="row row-video-posts">
              {posts.edges && posts.edges.length ? (
                posts.edges.map(({ node: post }, i) => (
                  <div className="col-md-4 py-4" key={i}>
                    {post.astroturfTv?.astroturfTvEmbed && (
                      <div className="ratio ratio-16x9">
                        <iframe
                          className="video-posts-iframe"
                          width="560"
                          height="315"
                          src={post.astroturfTv.astroturfTvEmbed}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}
                    {post.featuredImage && !post.astroturfTv?.astroturfTvEmbed && (
                      <Link
                        className="d-block product product-green"
                        to={post.uri}
                      >
                        <CmsImage
                          className="img-zoom img-fluid w-100 img-clipped mx-auto d-block "
                          image={post.featuredImage.node}
                        />
                      </Link>
                    )}

                    <Link className="text-decoration-none" to={post.uri}>
                      <h2 className="video-posts-text">{post.title}</h2>
                    </Link>
                  </div>
                ))
              ) : (
                <>
                  <h1>No Posts</h1>
                  <p>
                    This {type ? type : "category"} has no posts... check back
                    soon!
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {totalPages && totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      )}
    </>
  );
};

export default VideoPosts;
