import React from "react";

const BlogElem = ({ children }) => {
  return (
    <section className="blog-news-section">
      <div className="container">
        <div className="row mt-4">{children}</div>
      </div>
    </section>
  );
};

export default BlogElem;
