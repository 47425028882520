// Slugify a string
const convertId = (id) => {
  if (id === null) return false;

  return id
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

export default convertId;
