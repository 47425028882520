import React from "react";
import Link from "./link";

const Pagination = ({ totalPages, currentPage, baseUri }) => {
  baseUri = baseUri.startsWith("/") ? baseUri : "/" + baseUri;

  const PaginationLink = ({ page, children }) => (
    <li key={page} className="page-item">
      <Link className="page-link" to={baseUri + (page !== 1 ? page + "/" : "")}>
        {children}
      </Link>
    </li>
  );

  const totalPagesArray = [...Array(totalPages).keys()];

  return (
    // @TODO What's with this? Maybe delete this comment?
    // <ul className="pagination justify-content-center">
    //   <li className="page-item disabled">
    //     <a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
    //   </li>
    //   <li className="page-item"><a className="page-link" href="#">1</a></li>
    //   <li className="page-item"><a className="page-link" href="#">2</a></li>
    //   <li className="page-item"><a className="page-link" href="#">3</a></li>
    //   <li className="page-item">
    //     <a className="page-link" href="#">Next</a>
    //   </li>
    // </ul>
    <ul className="pagination justify-content-center">
      {currentPage !== 1 && (
        <PaginationLink page={currentPage - 1}>Previous</PaginationLink>
      )}
      {totalPagesArray &&
        totalPagesArray.map((page) => {
          page++;
          return (
            <PaginationLink key={page} page={page}>
              {page}
            </PaginationLink>
          );
        })}
      {currentPage !== totalPages && (
        <PaginationLink page={currentPage + 1}>Next</PaginationLink>
      )}
    </ul>
  );
};

export default Pagination;
